import React from "react";

export const Testimonies = (props) => {
  return (
    <div id="testimonies" style={{ paddingTop: 80 }}>
      <div className="container">
        <div className="section-title text-center">
          <h2>Témoignages de nos clients</h2>
          <p>Rien ne serait possible sans vous! 💜</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.slice(0, 6).map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  style={{ minHeight: "200px" }}
                  className="col-md-6"
                >
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt={d.alt} />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="row">
          {props.data
            ? props.data.slice(6).map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-8">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt={d.alt} />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>{" "}
      </div>
    </div>
  );
};
